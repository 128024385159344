import React from "react";
import { Navbar } from "./navbar";
import { FaDownload } from "react-icons/fa";
import "./styles.scss";

import { Feedback, SharelockFuncDesc } from "../../components";
export const SharelockInfo: React.FC = () => (
  <div className="text-center">
    <Navbar />
    <div className="container-sharelock">
      <SharelockFuncDesc />

      {/* <h1>Описание версии Шерлок для реестра отечественного ПО</h1> */}
            <div className="table-div">
               
        <table id="info-table">
          <tbody>
            <tr>
                            <td>
                             
                Ссылка на экземпляр программного обеспечения для проведения
                экспертной проверки
              </td>
              <td>
                <div>
                  <a href="https://sharelockru.azurewebsites.net/sharelock/ShareLockWin.zip">
                    <span className="documentNameSpan1">
                      Скачать для Windows
                      <FaDownload className="downloadIcon1" />
                    </span>
                  </a>
                </div>
                <div>
                  <a href="https://sharelockru.azurewebsites.net/sharelock/ubuntu.18.04-x64.zip">
                    <span className="documentNameSpan1">
                      Скачать для Ubuntu
                    </span>
                    <FaDownload className="downloadIcon1" />
                  </a>
                </div>
                <div>
                  <a href="https://sharelockru.azurewebsites.net/sharelock/sharelock.debian.zip">
                    <span className="documentNameSpan1">
                      Скачать для Debian
                    </span>
                    <FaDownload className="downloadIcon1" />
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Руководство пользователя программы ShareLock-Analyzer</td>

              <td>
                {" "}
                <a href="https://sharelockru.azurewebsites.net/sharelock/Руководство пользователя программы ShareLock-Analyzer.docx">
                  {" "}
                  <div>
                    <img
                      id="filesIcon1"
                      className="filesIcons1"
                      src="/images/word.png"
                      alt="DOCX"
                    />
                    <div>
                      {" "}
                      <span className="documentNameSpan1">
                        Руководство пользователя программы ShareLock-Analyzer
                      </span>{" "}
                      <span>
                        <FaDownload className="downloadIcon1" />
                      </span>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Функциональные возможности програмного обеспечения ShareLock-Analyzer
              </td>
              <td>
                <a href="https://sharelockru.azurewebsites.net/sharelock/Функциональные возможности программного обеспечения ShareLock-Analyzer.docx">
                  {" "}
                  <div>
                    <img
                      id="filesIcon1"
                      className="filesIcons1"
                      src="/images/word.png"
                      alt="DOCX"
                    />
                    <div>
                      {" "}
                      <span className="documentNameSpan1">
                        {" "}
                        Функциональные возможности програмного обеспечения
                        ShareLock-Analyzer
                      </span>{" "}
                      <span>
                        <FaDownload className="downloadIcon1" />
                      </span>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
              Инструкция по установке программного обеспечения ShareLock-Analyzer
              </td>
              <td>
                <a href="https://sharelockru.azurewebsites.net/sharelock/Инструкция по установке программного обеспечения ShareLock-Analyzer.docx">
                  {" "}
                  <div>
                    <img
                      id="filesIcon1"
                      className="filesIcons1"
                      src="/images/word.png"
                      alt="DOCX"
                    />
                    <div>
                      {" "}
                      <span className="documentNameSpan1">
                        {" "}
                        Инструкция по установке программного обеспечения ShareLock-Analyzer
                      </span>{" "}
                      <span>
                        <FaDownload className="downloadIcon1" />
                      </span>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
              Руководство по эксплуатации программного обеспечения
              </td>
              <td>
                <a href="https://sharelockru.azurewebsites.net/sharelock/Руководство по эксплуатации программного обеспечения.docx">
                  {" "}
                  <div>
                    <img
                      id="filesIcon1"
                      className="filesIcons1"
                      src="/images/word.png"
                      alt="DOCX"
                    />
                    <div>
                      {" "}
                      <span className="documentNameSpan1">
                        {" "}
                        Руководство по эксплуатации программного обеспечения
                      </span>{" "}
                      <span>
                        <FaDownload className="downloadIcon1" />
                      </span>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <a href="http://sharelock.ru/sharelock">
      Ссылка на экземпляр программного обеспечения для проведения экспертной
      проверки
    </a> */}
      <div className="sharelockInfoDocuments">
        <div className="downloadFilesDiv"></div>
      </div>
    </div>

    <Feedback />
  </div>
);
