import React from "react";
import { Carousel } from "react-bootstrap";
import { Section } from "../section";
import { ProjectCarouselItem } from "./components/projects-carousel-item";
import { ProjectsInfo } from "../../common";
import { useTranslation } from "react-i18next";

// Экран "Несколько наших последних проектов"
export const Projects: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Section id="sec-projects" color="color-1" wrapper>
      <div>
        <div className="main-video-wrapper">
          <video className="main-video" width="600" controls autoPlay>
            <source src={`${window.location.href}sharelock-video.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <h3> ШерЛок для Microsoft Office и LibreOffice, ожидается Р7-Офис</h3>
        <h4>
          Демо по распознаванию и защите конфиденциальных документов с
          использованием нейросети.
        </h4>
      </div>

    </Section>
  );
};
