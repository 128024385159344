import React, { useState, useEffect } from 'react';
import { ScrollInto } from './components/scroll-into';

import './styles.scss';

import '../../i18n/config';


import { useTranslation } from 'react-i18next';



// Навигация
export const Navbar = () => {
    const { t } = useTranslation();


    const [position, setPosition] = useState(window.pageYOffset);

    


    // Обработка прокрутки для смены типа navbar
    const handleScroll = () => setPosition(window.pageYOffset);

    // Обработка открытия dropdown
    // const handleDropdownOpen = function () {
    //     this.classList.toggle('active');
    //     let dropdownContent = this.nextElementSibling;
    //     if (dropdownContent.style.maxHeight) {
    //         dropdownContent.style.maxHeight = null;
    //         dropdownContent.style.overflow = 'hidden';
    //     } else {
    //         dropdownContent.style.maxHeight = dropdownContent.scrollHeight + 'px';
    //         setTimeout(() => {
    //             dropdownContent.style.overflow = 'visible';
    //         }, 10);
    //     }
    // }

    // Обработка случаев navbar при нажатии на другие части документа
    const handleDocumentClick = (e) => {
        // Нажатие на кнопку открытия-закрытия шторки
        // Инвертированное условие потому, что checked в проверяемом input выставляется уже ПОСЛЕ этого события click
        if (e.target.className === 'section-menu__button') {
            document.body.style.overflowY = !document.getElementById('menu-mobile').checked ? 'hidden' : 'scroll';
        }

        if ((e.target.classList[0] === 'menu__item' ||              // Элемент меню
            e.target.classList[0] === 'dropdown-content__item' ||   // Элемент выпадающего списка
            e.target.classList.contains('button--contacts') ||      // Кнопка "Свяжитесь с нами" в мобильном виде
            e.target.id === 'contacts-mobile') &&                   // Элемент, где лежит кнопка "Свяжитесь с нами" в мобильном виде
            document.getElementById('menu-mobile').checked) {       // Шторка отображается
            document.getElementById('menu-mobile').checked = false;
            document.body.style.overflowY = 'scroll';
        }

        if (e.target.id === 'dropdown__label' ||             // Название "Услуги"
            e.target.tagName === 'path' ||                   // Контейнер для SVG
            e.target.tagName === 'svg' ||                    // SVG со стрелочкой
            e.target.className === 'dropdown__arrow-down') { // Сама стрелочка
            return;
        }

        let content = document.getElementById('dropdown-content');
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
            content.style.overflow = 'hidden';
            document.getElementById('dropdown__label').classList.remove('active');
        }
    }

    // Для смены вида navbar при прокрутке используем свойство scroll, а также настраиваем navbar
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        // document.getElementById('dropdown__label').addEventListener('click', handleDropdownOpen);
        document.addEventListener('click', handleDocumentClick);
    }, [])

    return (
        <header className={`section-header section-header--black ${position > 750 ? 'headerScroll' : ''}`}>
          
            <div className="section-fixed">
                <div className="header-content d-flex align-items-center justify-content-between">
                    <ScrollInto selector="#sec-main" className="logo-container">
                        <img className="logo-container__img logo-container__img--small" src="/images/logo-last.svg" alt="Sharesoft" />
                        <img id="logo_sharelock" className="logo-container__img logo-container__img--full" src="/images/logo-last.svg" alt="Sharesoft" />

                        {/* <img id="logo_sharelock" className="logo-container__img logo-container__img--full" src="/images/logo_sharelock_svg.svg" alt="Sharesoft" /> */}
                    </ScrollInto>

                    <div className="section-menu d-flex flex-grow justify-content-end justify-md-content-center">
                        <input type="checkbox" id="menu-mobile" className="section-menu__open" />
                        <label htmlFor="menu-mobile" className="section-menu__button" />

                        <nav id="menu" className="menu menu--white">
                            <div className="dropdown dropdown--white order-2">
                                <span id="dropdown__label" className="dropdown__label">
                                {t('navbar.services')}
                                    <span className="dropdown__arrow-down">
                                        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 4L0.535899 0.249999L7.4641 0.25L4 4Z" fill="white" />
                                        </svg>
                                    </span>
                                </span>

                                <div id="dropdown-content" className="dropdown-content dropdown-content--header">
                                    <ScrollInto selector="#sec-developing" className="dropdown-content__item">
                                    {t('navbar.develop')}
                                    </ScrollInto>
                                    <ScrollInto selector="#sec-services" className="dropdown-content__item">
                                    {t('navbar.portals')}
                                    </ScrollInto>
                                    <ScrollInto selector="#sec-protection" className="dropdown-content__item">
                                    {t('navbar.protect-info')}
                                    </ScrollInto>
                                    <ScrollInto selector="#sec-support" className="dropdown-content__item">
                                    {t('navbar.support')}
                                    </ScrollInto>
                                    <ScrollInto selector="#sec-outstaffing" className="dropdown-content__item">
                                    {t('outstaffing.title')}
                                    </ScrollInto>
                                </div>
                            </div>

                            <ScrollInto selector="#sec-benefits" className="menu__item menu__item_header order-2">
                           Вопросы и ответы                           </ScrollInto>
                           
                            <ScrollInto selector="#sec-news" className="menu__item menu__item_header order-2">
                            Примеры защищенных файлов
                            </ScrollInto>

                                <ScrollInto selector="#sec-form" className="menu__item menu__item_header order-2">


                                Запрос стоимости ПО


                                </ScrollInto>





                           
                           

                            <div id="contacts-mobile" className="contacts-container d-flex d-md-none button button--contacts" onClick={() => window.open("https://support.sharelock.ru/")}>
                                <span>ПОДДЕРЖКА</span>

                            </div>
                            {/* <div  style={{"padding":"1px"}} className="menu__item menu__item_header order-2">
                                <button style={{"background-color":"Transparent", "color":"white","outline":"none"}} onClick={changeLanguage} value='ru'>RU</button>
                            </div>
                            <div  style={{"padding":"1px"}} className="menu__item menu__item_header order-2">
                                <button style={{"background-color":"Transparent", "color":"white","outline":"none"}} onClick={changeLanguage} value='en'>EN</button>
                            </div> */}
                        </nav>
                    </div>
                    

                    <div id="contacts-desktop" className="contacts-container d-none d-md-block button button--contacts" onClick={() => window.open("https://support.sharelock.ru/")}>
                            <span>ПОДДЕРЖКА</span>

                       

                    </div>

                    {/* <div  className="contacts-container d-none d-md-block locale">
                    <div  style={{"padding":"1px"}} className="menu__item menu__item_header order-2">
                                <button style={{"background-color":"Transparent", "color":"white","outline":"none"}} onClick={changeLanguage} value='ru'>RU</button>
                            </div>
                            <div  style={{"padding":"1px"}} className="menu__item menu__item_header order-2">
                                <button style={{"background-color":"Transparent", "color":"white","outline":"none"}} onClick={changeLanguage} value='en'>EN</button>
                            </div>
                    </div> */}
                   
                </div>
            </div>
        </header>
    );
};
