import React from "react";
import { Section } from "../section";

import { useState } from "react";
import "./styles.scss";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

// Экран "Преимущества работы с нами"
export const FuncInfoParagraphs: React.FC = () => {
  let [answerParagraphState1, setAnswerParagraphState1] = useState(false);
  let [answerParagraphState2, setAnswerParagraphState2] = useState(false);

  let [answerParagraphState3, setAnswerParagraphState3] = useState(false);

  let [answerParagraphState4, setAnswerParagraphState4] = useState(false);

  let [answerParagraphState5, setAnswerParagraphState5] = useState(false);

  let [answerParagraphState6, setAnswerParagraphState6] = useState(false);

  let [answerParagraphState7, setAnswerParagraphState7] = useState(false);

  let [answerParagraphState8, setAnswerParagraphState8] = useState(false);



  function answerClickHandler(id: number) {
    switch (id) {
      case 1:
        setAnswerParagraphState1(!answerParagraphState1);
        if(answerParagraphState1){
          document.getElementById("FAQContainer1")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer1")?.classList.add("FAQContainerBorder")

        }
        break;
      case 2:
        setAnswerParagraphState2(!answerParagraphState2);
        if(answerParagraphState2){
          document.getElementById("FAQContainer2")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer2")?.classList.add("FAQContainerBorder")

        }
        break;
      case 3:
        setAnswerParagraphState3(!answerParagraphState3);
        if(answerParagraphState3){
          document.getElementById("FAQContainer3")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer3")?.classList.add("FAQContainerBorder")

        }
        break;
      case 4:
        setAnswerParagraphState4(!answerParagraphState4);
        if(answerParagraphState4){
          document.getElementById("FAQContainer4")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer4")?.classList.add("FAQContainerBorder")

        }
        break;

      case 5:
        setAnswerParagraphState5(!answerParagraphState5);
        if(answerParagraphState5){
          document.getElementById("FAQContainer5")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer5")?.classList.add("FAQContainerBorder")

        }
        break;
      case 6:
        setAnswerParagraphState6(!answerParagraphState6);
        if(answerParagraphState6){
          document.getElementById("FAQContainer6")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer6")?.classList.add("FAQContainerBorder")

        }
        break;
      case 7:
        setAnswerParagraphState7(!answerParagraphState7);
        if(answerParagraphState7){
          document.getElementById("FAQContainer7")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer7")?.classList.add("FAQContainerBorder")

        }
        break;
      case 8:
        setAnswerParagraphState8(!answerParagraphState8);
        if(answerParagraphState8){
          document.getElementById("FAQContainer8")?.classList.remove("FAQContainerBorder")

        }
        else{
          document.getElementById("FAQContainer8")?.classList.add("FAQContainerBorder")

        }
        break;
    }
  }
  return (
    <Section
      id="sec-benefits"
      //   title={t("benefits.title")}
      color="#48979079"
      wrapper
    >
      <div>
        <h1 className="answersAndQuestionsH1">Оглавление  </h1>
        <div id="FAQContainer1">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              1.	Назначение программного обеспечения
            </div>{" "}
            <div>
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(1)}
              >
                {answerParagraphState1 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState1 ? (
            <div className="questionDiv">
              <br />
              <h3>
              Программное обеспечение ШЕРЛОК-Анализатор (ShareLock-Analyzer) предназначено для анализа текста на принадлежность к разделу текстов о физике.
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <hr />
        <div id="FAQContainer2">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
            2.	Функциональные возможности «ШЕРЛОК-Анализатор» (ShareLock-Analyzer):
            </div>{" "}
            <div>
              {" "}
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(2)}
              >
                {answerParagraphState2 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState2 ? (
            <div className="questionDiv">
              <br />
              <h3>
                <ul><li>•	Ввод текста пользователем;</li>
                <li>•	Анализ введенного текста;</li>
                <li>•	Выдача результата анализа пользователю.</li></ul>
              



              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
        <div id="FAQContainer3">
          <div className="answersSectionDiv">
            <div className="spanQuestion">
              {" "}
              3.	Минимальные требования к рабочей станции (ПК):
            </div>{" "}
            <div>
              <button
                className="answerBtn"
                onClick={() => answerClickHandler(3)}
              >
                {answerParagraphState3 ? (
                  <FaMinusCircle color="#48979079" />
                ) : (
                  <FaPlusCircle color="#48979079" />
                )}
              </button>
            </div>
          </div>
          {answerParagraphState3 ? (
            <div className="questionDiv">
              <br />
              <h3>
                {" "}
           





                <ul><li>     •	Процессор: Intel Core i3 или аналог.</li>
                <li>•	Объем оперативной памяти: 4 ГБ</li>
                <li>•	Объем жесткого диска: 128 ГБ</li>
                <li>•	Операционная система: Ubuntu 22.04 LTS 64 бита или аналог.</li>
                <li>•	Сетевая карта 100 Mbps.</li></ul>
             
              </h3>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <hr />
       
      
      </div>
    </Section>
  );
};
